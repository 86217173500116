@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

.grecaptcha-badge {
  opacity: 0 !important;
}

/* Scrollbar */ 
.stylledScroll {
  scrollbar-width: thin;
  scrollbar-color: rgb(156, 156, 156) #f2f2f2;
}
.stylledScroll::-webkit-scrollbar, html .stylledScroll::-webkit-scrollbar {
  height: 12px;
  width: 7px;
}

.stylledScroll::-webkit-scrollbar-track, html .stylledScroll::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.stylledScroll::-webkit-scrollbar-thumb, html .stylledScroll::-webkit-scrollbar-thumb {
  background-color: rgb(156, 156, 156);
  border-radius: 5px;
  border: 3px solid rgb(156, 156, 156);
}
